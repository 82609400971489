//Dependencies
import { useState, useEffect, useContext, useMemo, createContext } from 'react';
import { doc, getDoc, onSnapshot } from "firebase/firestore";

//Providers
import { useAuthentication } from '../providers/authentication';
import { useUser } from '../providers/user';

//Chakra
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

//Helpers
import { db, getGCStorageBlobByName } from '../helpers/firebase';
import { setStorage, getStorage } from '../helpers/storage';


const MarketsContext = createContext();

function useMarkets() {
  const context = useContext(MarketsContext);
  if (!context) {
    throw new Error(`useMarkets must be used within a MarketsProvider`);
  }

  return context;
}

function MarketsProvider(props) {
  const {isLoading} = useAuthentication();
  const {user} = useUser();
  const [markets, setMarkets] = useState([]);
  const [marketIds, setMarketIds] = useState([]);
  const [regions, setRegions] = useState([]);
  const [token, setToken] = useState(null);
  // const [filters, setFilters] = useState([]); //Disabled until possible future need. Regions + Wave Finder may completely replace this.
  const [showSearch, setShowSearch] = useState(true);
  const [alert, setAlert] = useState(false);

  const value = useMemo(() => ({
    markets, setMarkets,
    marketIds, setMarketIds,
    regions, setRegions,
    token, setToken,
    // filters, setFilters,
    showSearch, setShowSearch,
  }), [markets, marketIds, regions, showSearch, token]);

  // Monitor when package timestamp changes
  useEffect(() => {
    const prevPackage = getStorage('user', 'package');

    // When user state updates, check for updated time stamp based on user package
    const getUpdatedTimestamp = async (user) => {
      const docRef = doc(db, "site", "config");

      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();

          console.log('Updating last market update timestamp...', data[`updated-${user?.package}`]);
          // setStorage('user', {[`updated-${user.package}`]: data[`updated-${user.package}`]});
          setStorage('user', {updated: data[`updated-${user?.package}`], package: user?.package});
        } else {
          console.log("No such document!");
        }
      } catch(e) {
        console.error(`Unable to retrieve market timestamp: ${e.code}`);
      }
    }

    if(user) {
      getUpdatedTimestamp(user);

      const unsubscribe = onSnapshot(doc(db, "site", "config"), (doc) => {
        const data = doc.data();
  
        // const prevUpdated = getStorage('user', `updated-${user.package}`);
        const prevUpdated = getStorage('user', `updated`);
        const currUpdated = data[`updated-${user?.package}`];
  
        if(new Date(prevUpdated).getTime() !== new Date(currUpdated).getTime()) {
          setAlert({
            message: 'EWAVES has updated charts. Please refresh your browser.',
            error: false
          });
        }

        if(user?.package && prevPackage) {
          console.log('Comparing packages...', user?.package, prevPackage);
          if(user?.package !== prevPackage) {
            window.location.reload();
          }
        }
      });

      // Make sure to unsubscribe from our firestore listener
      return () => {
        unsubscribe();
        setAlert(false);
      };
    }
  }, [user]);

  // On auth change
  useEffect(() => {
    let active = false;

    if(user) {
      const cleanup = new AbortController();

      // We get data and wait to see if it's correct, otherwise show NoData message
      const getMarketsJSON = async () => {
        const urlBlob = await getGCStorageBlobByName('assets', `${user?.package}.json`);
        const url = URL.createObjectURL(urlBlob);

        try {
          const response = await fetch(url, {signal: cleanup.signal});
          const json = await response.json();

          return json;
        } catch(error) {
          if(error instanceof SyntaxError) {
            console.error('There was a syntax error:', error);
          } else {
            console.error('There was an error:', error);
          }
        }
      }
      
      if(!active) {
        active = true;
        console.log('Getting markets from package...', user?.package);
        getMarketsJSON()
        .then(data => {
          if(data) {
            setToken(data.WavesToken);
            setMarkets(data.Markets);
            setRegions(data.Regions);
          }
        })
        .catch(err => {
          console.warn(`Error fetching package: ${err.message}`);
          setAlert({
            message: 'There was a problem getting the package data file. Please refresh your browser to resolve the issue.',
            error: true
          });
        });
      }
      
      return () => {
        setAlert(false);
        active = false;
        cleanup.abort();
      }
    }
  }, [user]);

  return (
    <MarketsContext.Provider value={value} {...props}>
      {!isLoading && alert &&
        <Alert status='error' zIndex={'popover'}>
          <AlertIcon />
          <AlertTitle>{alert.message}</AlertTitle>
          {alert.error && <AlertDescription>Please contact support if the problem persists.</AlertDescription>}
        </Alert>
      }
      {props.children}
    </MarketsContext.Provider>
  )
}

export {MarketsProvider, useMarkets};