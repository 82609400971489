//Dependencies
import { useState, useEffect, useContext, useMemo, useRef, useCallback, createContext } from 'react';
import { doc, getDoc } from "firebase/firestore";

//Providers
import { useMarkets } from './markets';
import { useUser } from './user';
import { useView } from './view';

//Helpers
import { db } from '../helpers/firebase';


const WaveFinderContext = createContext();

function useWaveFinder() {
  const context = useContext(WaveFinderContext);
  if (!context) {
    throw new Error(`useWaveFinder must be used within a WaveFinderProvider`);
  }

  return context;
}

function WaveFinderProvider(props) {
  const {token} = useMarkets();
  const {user} = useUser();
  const {itemsPerPage} = useView();

  //Used internally by provider
  const [wf, setWf] = useState(null); //Gets main functionality from helper script and worker

  //Available through useWaveFinder to every component under the provider
  const [maxWaves, setMaxWaves] = useState(null); //Returned from finding waves with query
  const [waves, setWaves] = useState(null); //Array of wave objects consisting of ids and zooms
  const [queryInfo, setQueryInfo] = useState(null); //Gets default information to generate UI elements for wavefinder
  const [waveIds, setWaveIds] = useState([]); //Ids of waves to retrieve. Empty gets everything
  const [sortBy, setSortBy] = useState('elliotticity'); //Set how waves are sorted via find_waves. Values come for queryInfo
  const [sortAsc, setSortAsc] = useState(false); //Boolean for true (ascending) or false (descending)
  const [query, setQuery] = useState({}); //Query consists of args passed (IE: sorting and actual "query" object of waves)
  const [templates, setTemplates] = useState([]); //Templates are pre-made query setups a user can load into the wave query builder
  const [pageNumber, setPageNumber] = useState(0); //Current page number of waves to return
  const [wfActive, setWfActive] = useState(false);
  const loading = useRef(null);

  const findWaves = useCallback(() => {
    return wf.find_waves(waveIds, {"sortBy": sortBy, "sortAscending": sortAsc, "query": query});
  }, [wf, waveIds, sortBy, sortAsc, query]);
  const getWaves = useCallback(() => {
    return wf.get_waves(pageNumber, itemsPerPage);
  }, [wf, pageNumber, itemsPerPage]);

  //Passed to context
  const value = useMemo(() => ({
    waveIds, setWaveIds,
    sortBy, setSortBy,
    sortAsc, setSortAsc,
    query, setQuery,
    pageNumber, setPageNumber,
    wfActive, setWfActive,
    waves,
    maxWaves,
    queryInfo,
    templates
  }), [waveIds, sortBy, sortAsc, query, pageNumber, waves, maxWaves, queryInfo, templates, wfActive]);

  //On initial render, get templates data from firebase
  useEffect(() => {
    const getUpdatedTemplates = async () => {
      const docRef = doc(db, "site", "config");

      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const fbTemplates = JSON.parse(docSnap.data().templates);
          console.log('Getting latest templates...');
          console.log('Templates', fbTemplates);
          setTemplates(fbTemplates);
        } else {
          console.log("No templates found!");
        }
      } catch(e) {
        console.error(`Unable to retrieve template data: ${e}`);
      }
    }

    getUpdatedTemplates();
  }, []);

  // Populate wavefinder module from script
  useEffect(() => {
    if(user?.package) {
      // URL of the JavaScript file you want to load
      const moduleUrl = `${process.env.REACT_APP_ASSETS_PATH}/${user?.package}/wavefinder.js`;

      // Use dynamic import to load the module
      import(/* webpackIgnore: true */ moduleUrl)
        .then((mod) => {
          setWf(mod);
        })
        .catch((error) => {
          console.error('Failed to load module:', error);
        });
    }
  }, [user?.package]);

  //Grab the WaveFinder from global window object (loaded in index.html) and store it in state for using, depends on token
  useEffect(() => {
    const getQueryInfo = async () => {
      try {
        loading.current = true;
        const template = await wf.get_query_info(`${process.env.REACT_APP_ASSETS_PATH}/${user?.package}/`, token);
        setQueryInfo(template);
      } catch (err) {
        console.error(`Error getting query template: ${err}`);
      } 
    }

    if(wf && token && user?.package) {
      if(!loading.current) {
        getQueryInfo();
      }
    }

    return () => {
      loading.current = false;
    }
  }, [wf, user?.package, token]);

  //Executes query and returns num of waves found to state if wave finder and query info have already been retrieved then gets first page of wave results
  useEffect(() => {
    if(queryInfo) {
      loading.current = true;
      findWaves()
        .then(num => {
          console.log('Found waves', num);
          setMaxWaves(num);
          getWaves()
            .then(waves => {
              console.log('Then got waves', waves);
              setWaves(waves);
              loading.current = false;
            })
            .catch(err => {
              console.error(`Error getting waves: ${err}`);
            });
        })
        .catch(err => {
          console.error(`Error finding waves: ${err}`);
        });
    }
  }, [queryInfo, waveIds, query, sortBy, sortAsc, findWaves, getWaves]);

  //Gets wave data from WASM and stores in waves array
  useEffect(() => {
    // console.log('Getting waves...', pageNumber, itemsPerPage);
    if(!loading.current && (maxWaves || maxWaves === 0)) {
      getWaves()
      .then(waves => {
        console.log('Updated waves', waves);
        setWaves(waves);
        loading.current = false;
      })
      .catch(err => {
        console.error(`Error getting waves: ${err}`);
      });
    }
  }, [pageNumber, itemsPerPage, maxWaves, getWaves]);

  useEffect(() => {
    // console.log('Markets', waveIds);
  }, [waveIds]);

  useEffect(() => {
    // console.log('Query', query);
  }, [query]);

  return (
    <>
      {user && queryInfo &&
        <WaveFinderContext.Provider value={value} {...props} />
      }
    </>
  )
}

export {WaveFinderProvider, useWaveFinder};