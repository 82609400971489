//Dependencies
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';

//Chakra
import {
  Box,
  Button,
  Flex,
  Heading,
  VStack,
  HStack,
} from '@chakra-ui/react';

//Providers
import { useChart } from '../providers/chart';

//Views
import Full from '../views/full';

//Components
import {
  Breadcrumbs,
  ChartFooter,
  Fullscreen,
  DownloadBlobButton,
} from '../components';


export default function Single() {
  const { market, zoom, chartBlob } = useChart();
  const navigate = useNavigate();
  const breadcrumbs = useReactRouterBreadcrumbs();

  // create filename in this format: Symbol-YYMMDD-Zoom.svg
  const filename = `${market?.Title}-${(new Date()).toISOString().slice(0,10).replace(/-/g,"")}-z${zoom}`;

  const buttonStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    h: 10,
    px: 4,
    py: 4,
    bgColor: 'primary.200',
    color: 'blackAlpha.900',
    borderRadius: 4,
    fontWeight: '500',
    lineHeight: '1',
    '&:hover': {
      bgColor: 'gray.200',
      textDecoration: 'none'
    }
  }

  return (
    <Flex className={'single-view'} flexDirection={'column'} pb={5} width={'100%'}>
      <Box mb={{base: 2, md: 3, 'xl': 4}}>
        <Box width={'100%'} textAlign={'left'}>
          <Button size='sm' color={'green.500'} variant={'link'} aria-label='Go back' bgColor={'transparent'} leftIcon={<FontAwesomeIcon icon="fa-solid fa-angle-left" />} onClick={() => {navigate(-1)}}>BACK</Button>
        </Box>
        <Flex flexDirection={{base: 'column', md: 'row'}} width={'100%'} alignItems={'flex-start'} justifyContent={'space-between'}>
          <VStack alignItems={'flex-start'}>
            <Heading size={'xl'} lineHeight={'1'}>{market?.Description}</Heading>
            <Breadcrumbs crumbs={breadcrumbs} />
          </VStack>
          <VStack spacing={0} alignItems={'flex-end'} mt={{base: 10, md: 0}}>
            <HStack>
              <Fullscreen
                open={<Button leftIcon={<FontAwesomeIcon icon="fa-sharp fa-solid fa-maximize" size="lg" />} sx={buttonStyles}>Fullscreen</Button>}
              >
                <Full hasHeader={false} hasFooter={false} />
              </Fullscreen>
              <DownloadBlobButton blob={chartBlob} filename={filename} />
            </HStack>
          </VStack>
        </Flex>
      </Box>
      <ChartFooter />
      <Full hasHeader={false} hasFooter={false} />
    </Flex>
  );
}